import React from 'react'
import { Link } from "gatsby"
import { GatsbyImage  } from 'gatsby-plugin-image'

const Product = ({shortName, shortDescription, url, image}) => {
  return (
    <Link to={url} className='block bg-gray-10 h-full group'>
      <div className='aspect-w-15 aspect-h-16 bg-primary-light'>
        <div className='transition-opacity duration-300 ease-in-out bg-white z-10 opacity-0 group-hover:opacity-50'></div>
        <div>
          <GatsbyImage
            image={image}
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt='Product'
            className='h-full'
          />
        </div>
      </div>
      <div className='px-4 py-4 bg-gray-10'>
        <h3 className='text-secondary font-semibold lg:text-lg'>{shortName}</h3>
        <div className='lg:text-lg font-light'>
          {shortDescription}
        </div>
      </div>
    </Link>
  )
}

export default Product
