import React, { useRef, useState } from 'react'
import { StaticImage, GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Lightbox from 'react-image-lightbox'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactSection from '../components/organisms/ContactSection'
import Banner from '../components/organisms/Banner'
import Product from '../components/molecules/Product'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import 'react-image-lightbox/style.css';

const ProductPage = ({ pageContext, data }) => {
  const { t } = useTranslation()
  const images = data.product.productGallery.map(({ localFile }) => getImage(localFile.childImageSharp))
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const sliderRef = useRef('slider');

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    lazyLoad: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Layout>
      <Seo title={t('pageTitle')} />

      <section className='px-4 py-8 md:py-20'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <span className='block text-secondary text-lg uppercase font-bold has-decorator mb-10'>
            {t('pageTitle')}
          </span>
          <div className='flex flex-wrap -mx-4 xl:-mx-8'>
            <div className='px-4 xl:px-8 w-full lg:w-1/2 lg:border-r-3 lg:border-secondary'>
              <div className='flex -mx-4'>
                <div className='px-4 w-3/4'>
                  <div className='aspect-w-10 aspect-h-10'>
                    <div>
                      <GatsbyImage
                        image={getImage(data.product.mainImage?.localFile)}
                        alt={data.product.name}
                        className='shadow-lg h-f'
                        onClick={() => {
                          setIsOpen(true)
                          setPhotoIndex(0)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='px-4 w-1/4'>
                  <div className='flex flex-col -my-3'>
                    {images.map((img, imgIndex) => {
                      return (
                        <div
                          className='py-3 mb-2 focus:outline-none'
                          key={imgIndex}
                          role='button'
                          tabIndex={0}
                          onClick={() => {
                            setIsOpen(true)
                            setPhotoIndex(imgIndex)
                          }}
                          onKeyDown={() => {
                            setIsOpen(true)
                            setPhotoIndex(imgIndex)
                          }}
                        >
                          <div className="product-image-thumb cursor-pointer shadow-lg">
                            <GatsbyImage
                              image={img}
                              alt={img.thumbAlt || ''}
                            />
                          </div>
                        </div>
                      )
                    })}

                    {isOpen && (
                      <Lightbox
                        mainSrc={getSrc(images[photoIndex])}
                        nextSrc={getSrc(images[(photoIndex + 1) % images.length])}
                        prevSrc={getSrc(images[(photoIndex + images.length - 1) % images.length])}
                        onCloseRequest={() => { setIsOpen(false) }}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='px-4 xl:px-8 w-full lg:w-1/2 mt-6 lg:mt-0'>
              <h1 className='font-semibold text-xl uppercase'>{data.product.name}</h1>
              <div>
                <span className='inline-block font-medium mb-3 mr-2'>{t('productClassHeader')}</span>
                <span className='inline-block mb-3'>{data.product.product_class}</span>
              </div>
              <span className='block font-medium mb-3'>{ }</span>
              <div className='font-light mb-6'>
                {data.product.description}
              </div>
              <span className='block font-medium mb-3'>{t('documentationHeader')}</span>
              <div className='flex flex-col sm:flex-row'>
                <div className='flex-shrink-0'>
                  <a href={data.product.documentation.localFile.url} target='_blank' rel='noreferrer' className='h-full group'>
                    <GatsbyImage
                      image={getImage(data.product.docThumb.localFile.childImageSharp)}
                      alt={data.product.docThumb.alternativeText || ''}
                      quality={95}
                      width={120}
                      className='shadow-lg'
                    />
                  </a>
                </div>
                {data.product.productFile1 &&
                  <div className='sm:ml-6 lg:ml-10 mt-4 sm:mt-0'>
                    {data.product.productFile1 &&
                      <div className='py-1 flex items-center'>
                        <span className='inline-block bg-secondary h-0.5 w-4 mr-1'></span>
                        <a href={data.product.productFile1.pdf.localFile.url} target='_blank' rel='noreferrer' className='hover:text-secondary transition-colors ease-in-out duration-300'>
                          {data.product.productFile1.name}</a>
                      </div>
                    }
                    {data.product.productFile2 &&
                      <div className='py-1 flex items-center'>
                        <span className='inline-block bg-secondary h-0.5 w-4 mr-1'></span>
                        <a href={data.product.productFile2.pdf.localFile.url} target='_blank' rel='noreferrer' className='hover:text-secondary transition-colors ease-in-out duration-300'>
                          {data.product.productFile2.name}</a>
                      </div>
                    }

                    {data.product.productFile3 &&
                      <div className='py-1 flex items-center'>
                        <span className='inline-block bg-secondary h-0.5 w-4 mr-1'></span>
                        <a href={data.product.productFile3.pdf.localFile.url} target='_blank' rel='noreferrer' className='hover:text-secondary transition-colors ease-in-out duration-300'>
                          {data.product.productFile3.name}</a>
                      </div>
                    }

                    {data.product.productFile4 &&
                      <div className='py-1 flex items-center'>
                        <span className='inline-block bg-secondary h-0.5 w-4 mr-1'></span>
                        <a href={data.product.productFile4.pdf.localFile.url} target='_blank' rel='noreferrer' className='hover:text-secondary transition-colors ease-in-out duration-300'>
                          {data.product.productFile4.name}</a>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='px-4 border-b-12 lg:border-b-36 border-secondary md:border-secondary py-8'>
        <div className='w-full max-w-screen-xl mx-auto'>
          <span className='block text-primary text-lg uppercase font-bold has-decorator mb-10'>
            {t('otherProductsHeader')}
          </span>
          <div className='-mx-4 xl:-mx-8'>
            <Slider {...settings} ref={sliderRef}>
              {data.allProducts.nodes.map(({ id, short_name, short_description, locale, slug, mainImage }) => {
                return (<div key={id} className='px-4 xl:px-8'>
                  <Product shortName={short_name} shortDescription={short_description} url={`/${locale}/produkt/${slug}`} image={getImage(mainImage?.localFile)} />
                </div>)
              })}
            </Slider>
          </div>
          <div className='flex items-center justify-center mb-6 mt-12'>
            <button className='text-primary hover:text-secondary button-arrow button-arrow--left' onClick={previous}>
              <span className='sr-only'>{t('prevButton')}</span>
            </button>
            <Link to="/produkty" className='button button--primary mx-6'>{t('seeMoreProductsButton')}</Link>
            <button className='text-primary hover:text-secondary button-arrow button-arrow--right' onClick={next}>
              <span className='sr-only'>{t('nextButton')}</span>
            </button>
          </div>
        </div>
      </section>

      <Banner
        image={
          <StaticImage
            src="../images/banner3.jpg"
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt={t('bannerSection.header')}
            style={{
              height: '100%',
            }}
          />
        }
        text={t('bannerSection.header')}
      />
      <ContactSection />
    </Layout>
  )
}

export default ProductPage;

export const pageQuery = graphql`
  query ImagesForGallery($language: String!, $id: String) {
    locales: allLocale(filter: {ns: {in: ["common", "productPage"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allProducts: allStrapiProducts(
      sort: {fields: strapiId, order: DESC}
      filter: { locale: { eq: $language }}
    ) {
      nodes {
        id
        name
        short_name
        description
        short_description
        slug
        locale
        mainImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        localizations {
          locale
        }
      }
    }
    product: strapiProducts(id: {eq: $id}) {
      name
      short_name
      description
      product_class
      locale
      slug
      mainImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      productGallery {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      docThumb {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 120)
          }
        }
      }
      documentation {
        localFile {
          url
        }
      }
      productFile1 {
        pdf {
          localFile {
            url
          }
        }
        name
      }
      productFile2 {
        pdf {
          localFile {
            url
          }
        }
        name
      }
      productFile3 {
        pdf {
          localFile {
            url
          }
        }
        name
      }
      productFile4 {
        pdf {
          localFile {
            url
          }
        }
        name
      }
      localizations {
        id
        locale
        published_at
      }
    }
  }
`
